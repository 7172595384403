.header-component {
  background-color: #053D76;
}

@media (min-width: 900px) {
  .header-component {
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}

.header-component .wrapper {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #053D76;
}

@media (min-width: 900px) {
  .header-component .wrapper .left {
    width: 24%;
    display: flex;
    justify-content: center;
  }
}

.header-component .wrapper .right {
  justify-content: flex-end;
  display: flex;
}

@media (min-width: 900px) {
  .header-component .wrapper .right {
    width: 76%;
  }
}

.header-component .wrapper .right .dp {
  font-weight: bold;
}

.header-component .wrapper .logo {
  max-height: 6em;
}

.header-component .wrapper .menu a {
  margin: 0 1em;
}

.ant-dropdown-menu {
  min-width: 9em;
}
