.footer-component {
  background: #102544;
  padding: 1.5em 2em;
  display: flex;
  justify-content: flex-end;
}

.footer-component .powered {
  margin: 0;
  font-size: 0.875em;
  line-height: 16px;
  color: #fff;
  display: flex;
}

.footer-component .logoFooter {
  display: block;
}
