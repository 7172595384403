:root {
  --main-bg-color: #F8F8F8;
  --main-panel-color: #EBEBEB;
  /* --primary-color: #233D7A;  */
  --primary-color: #801854;
  --default-color: #474747;
  --txt-color: #95989A;
  --boxed-width: 116.1em;
}

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-size: 14px;
  color: #000;
}

._boxed {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*
._btn {
    position: relative;
    z-index: 0;
    min-width: 7.5em;
    min-height: 2em;
    border: none;
    border-radius: .3em;
    font-size: 2em;
    overflow: hidden;
    cursor: pointer;
}

._btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    opacity: 0;
    transition: all ease-in-out 200ms;
}

._btn:disabled::before {
    background-color: rgba(255, 255, 255, .25);
    opacity: 1;
}

._btn:hover::before {
    opacity: 1;
}

._btn_primary {
    background-color: var(--primary-color);
    color: #fff;
}

._btn_default {
    color: #fff;
    background-color: #ababab;
}

.content {
    margin: 0 auto;
}

.block {
    margin: 1em 0;
}

.btn_actions {
    padding: 1em 0
}

.btn {
    background-color: #801854 !important;
    border-color: #801854 !important;
    padding: 1.5em;
    color: #fff !important;
}

.btn1 {
    padding: 1.2em 1.4em !important;
    line-height: inherit !important;
    height: inherit !important;
    font-size: 22px !important;
    font-weight: bold;
}
*/
a,
a:visited,
a:active,
a:hover {
  color: #236394;
}

.subtitle2 {
  font-size: 28px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.subtitle3 {
  font-size: 22px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.button {
  display: inline-block;
  width: 100%;
  padding: .6em 1em;
  margin: .5em 0;
  border-radius: 5px;
  color: #fff !important;
  font-weight: bold;
  font-size: 1.1em;
  text-align: left;
  border: none !important;
  background-color: #20AAB6;
  cursor: pointer;
}

.button.primary {
  background-color: #236394;
}

.button.secondary {
  background-color: #44A0DC;
}

.button.terciary {
  background-color: #009046;
}

.button.cuaternary {
  background-color: #20AAB6;
}

.button:disabled {
  background-color: #8498A7;
}

.button span {
  background-position: 0 center;
  background-repeat: no-repeat;
  padding: 0 0 0 1.8em;
}

.button span.list {
  background-image: url("./assets/img/ico-list.svg");
}

.button span.geo {
  background-image: url("./assets/img/ico-geo.svg");
}

.button.full {
  width: 100%;
  display: block;
}

.button.large {
  padding: 1.2em 2.2em;
}

.button.custom1 {
  padding: .5em 2em;
  height: 2.4em;
}

@media (min-width: 900px) {
  .button.custom1 {
    margin: 1.2em 0 0 0;
  }
}

.button.custom2 {
  padding: .5em 2em;
  font-size: 1em;
}

.text-center {
  text-align: center;
}

@media (min-width: 900px) {
  .content {
    display: flex;
    flex: 1 0;
  }
}

.content .sidebar {
  padding: 1em 0.7em;
}

@media (min-width: 900px) {
  .content .sidebar {
    width: 20%;
    margin-top: 5.5em;
    position: relative;
  }
  .content .sidebar .button {
    position: fixed;
    top: 6em;
    left: 1em;
    width: 15em;
  }
}

@media (min-width: 900px) {
  .content .mainbar {
    width: 80%;
    margin-top: 5.5em;
  }
}

.content .mainbar .title {
  background: #E8E7ED;
  color: #A1AAB3;
  font-size: 1.6em;
  font-weight: bold;
  padding: .5em 1em;
  margin: 0;
}

.content .mainbar .title a {
  text-decoration: none;
  color: #A1AAB3;
}

.content .mainbar .title a:hover {
  color: #A1AAB3;
  text-decoration: underline;
}

.content .mainbar .subtitle {
  color: #00325D;
  font-size: 1.4em;
  font-weight: bold;
  text-align: left;
  margin-bottom: .5em;
}

.content .mainbar .dato {
  font-size: 1em;
  color: #000;
  text-align: left;
  margin-bottom: .2em;
}

.content .mainbar .subtitle2 {
  color: #00325D;
  font-size: 1.4em;
  font-weight: bold;
  text-align: left;
  margin-bottom: .5em;
}

.content .mainbar .subcontent {
  padding: 1em;
}

.content .mainbar .subcontent.msj {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (min-width: 768px) {
  .content .mainbar .subcontent {
    padding: 1em 2em;
  }
}

.content .mainbar .options {
  margin-bottom: 1em;
}

.content .mainbar .results {
  /*background: #F1F1F1;*/
  overflow-x: scroll;
}

.content .mainbar .results-content {
  width: 1000px;
}

@media (min-width: 1000px) {
  .content .mainbar .results-content {
    width: inherit;
  }
}

@media (min-width: 1000px) {
  .content .mainbar .results {
    overflow-x: inherit;
  }
}

.content .mainbar .detalle {
  margin: 1em 0;
}

.content .mainbar .detalleHeader {
  background: #D5D5D5;
  color: #00325D;
  font-size: 1.2em;
  font-weight: bold;
  padding: 1em;
}

.content .mainbar .detalleBody {
  background: #F1F1F1;
  padding: .5em 1em;
}

.content .mainbar .detalle .panel {
  margin: 1em 0;
}

.content .mainbar .detalle .panel.desestimado .panelHeader {
  background-color: #993988;
}

.content .mainbar .detalle .panelHeader {
  background: #00325D;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  padding: .8em 1.2em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.content .mainbar .detalle .panelHeader .ico {
  background-position: 0 center;
  background-repeat: no-repeat;
  padding: 0 0 0 2em;
}

.content .mainbar .detalle .panelHeader .ico.contacto {
  background-image: url("./assets/img/ico-contacto.svg");
}

.content .mainbar .detalle .panelHeader .ico.visita {
  background-image: url("./assets/img/ico-visita.svg");
}

.content .mainbar .detalle .panelHeader .ico.califica {
  background-image: url("./assets/img/ico-califica.svg");
}

.content .mainbar .detalle .panelHeader .ico.documentado {
  background-image: url("./assets/img/ico-documentado.svg");
}

.content .mainbar .detalle .panelHeader .ico.riesgo {
  background-image: url("./assets/img/ico-riesgo.svg");
}

.content .mainbar .detalle .panelHeader .ico.desembolso {
  background-image: url("./assets/img/ico-desembolso.svg");
}

.content .mainbar .detalle .panelHeader .ico.desestimado {
  background-image: url("./assets/img/ico-desestimado.svg");
}

.content .mainbar .detalle .panelBody {
  background: #fff;
  padding: 1em 0;
}

@media (min-width: 768px) {
  .content .mainbar .detalle .panelBody {
    display: flex;
    flex-wrap: wrap;
  }
}

.content .mainbar .detalle .panelCol {
  width: 100%;
  padding: 0 1.5em 2em;
}

@media (min-width: 768px) {
  .content .mainbar .detalle .panelCol:nth-child(odd) {
    border-right: #9FB2CA 1px solid;
  }
}

.content .mainbar .detalle .panelCol:first-child {
  border-left: none;
}

@media (min-width: 768px) {
  .content .mainbar .detalle .panelCol {
    width: 50%;
  }
}

.content .mainbar .detalle .panelCustom {
  width: 100%;
  padding: 0 1.5em .5em;
}

@media (min-width: 768px) {
  .content .mainbar .detalle .panelCustom {
    display: flex;
    flex-wrap: wrap;
  }
}

.content .mainbar .detalle .panelCustomRow {
  width: 100%;
  padding: 1em 0;
}

@media (min-width: 768px) {
  .content .mainbar .detalle .panelCustomRow {
    display: flex;
  }
}

.content .mainbar .detalle .panelCustomRowBlock {
  padding: 0 1em 0 0;
}

.content .mainbar .detalle .panel p {
  font-size: 1em;
  margin-bottom: .25em;
  color: #8498A7;
}

.content .mainbar .detalle .panel a {
  color: #44A0DC;
  font-size: 1em;
  text-decoration: underline;
}

.content .mainbar .detalle .panel .label {
  margin-top: 1em;
}

.content .mainbar .detalle .panel .resaltado {
  color: #236394;
  font-weight: bold;
}

.content .mainbar .detalle .panel .check {
  background-position: 0 1px;
  background-repeat: no-repeat;
  padding: 0 0 0 1.8em;
  background-image: url("./assets/img/ico-checkbox.svg");
  background-size: 20px 20px;
}

.content .mainbar .detalle .panel .tabulado {
  width: 6em;
  display: inline-block;
}

.labelSelect {
  margin: .5em 0 .3em .8em;
  color: #236394;
  font-weight: bold;
}

@media (min-width: 900px) {
  .labelSelect {
    margin-top: 0;
  }
}

.tabla-procesos-col1 {
  width: 60px;
}

.tabla-procesos-col2 {
  min-width: 350px;
}

.tabla-procesos-col3 {
  width: 100px;
}

.tabla-procesos-col4 {
  width: 100px;
}

.tabla-procesos-col5 {
  width: 100px;
}

.tabla-procesos-col6 {
  width: 140px;
  text-align: center;
}

.form-row {
  margin-top: .8em;
  margin-bottom: .8em;
}

.form-label {
  color: #8498A7;
  display: block;
  font-size: 1em;
  margin-bottom: .5em;
  font-weight: bold;
}

.form-label.dark {
  color: #fff;
}

.form-input {
  background-color: #fff;
  border: 1px solid #8498A7;
  color: #8498A7;
  display: block;
  font-size: 1.1em;
  margin-bottom: .5em;
  padding: .6em 1em;
  width: 100%;
  border-radius: 5px;
}

.form-input:focus {
  outline: 0;
}

.form-input.dark {
  background-color: #3A86C0;
  color: #fff;
  border: 1px solid #3A86C0;
}

.form-input.dark:focus {
  background-color: #3A86C0;
  outline: 0;
}

.form-input.dark::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}

.form-input.dark:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}

.form-input.dark::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}

.form-input.dark:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.form-input.dark::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.form-input.dark::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}

.form-input.ico-user {
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 2.4em;
  background-image: url("./assets/img/ico-user.svg");
}

.form-input.ico-pass {
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 2.4em;
  background-image: url("./assets/img/ico-clave.svg");
}

.form-helper {
  color: #fff;
  font-size: 1em;
  text-align: right;
  margin-bottom: .5em;
}

@media (min-width: 900px) {
  .form-helper {
    margin-bottom: 1em;
  }
}

.form-helper a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  text-align: right;
}

.form-helper a:hover {
  text-decoration: underline;
}

.message {
  font-size: 1em;
  text-align: center;
}

.message-error {
  color: #c33030;
}

.message-success {
  color: #00b8a0;
}

/*SETEAMOS CLASES DE ANT*/
.ant-modal-title {
  color: #00325D !important;
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.flex-end {
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .bordeLeft {
    border-left: #9FB2CA 1px solid;
  }
}
