.main-component {
  position: relative;
}

@media (min-width: 900px) {
  .main-component {
    position: relative;
    display: flex;
    flex: 1 0 50%;
  }
}

.main-component .main-title {
  margin-top: 0;
  margin-bottom: 1.85em;
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
  color: var(--default-color);
}

.main-component .subtitle {
  margin: 0;
  font-size: 1.6em;
  text-align: center;
  color: var(--default-color);
}

.main-component .container {
  display: block;
  max-width: 46em;
  margin-left: auto;
  margin-right: auto;
}

@keyframes dots {
  0% {
    content: '';
  }
  15% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}
