.login-wrap {
  background: #102544 url("../../assets/img/bg.svg") no-repeat center center;
  background-size: cover;
  padding: 1em;
}

@media (min-width: 900px) {
  .login-wrap {
    padding: 3em 5em;
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
  }
}

.logo-wrap {
  text-align: center;
}

@media (min-width: 900px) {
  .logo-wrap {
    text-align: left;
  }
}

.logo-wrap .logo {
  margin-bottom: 1em;
}

.logo-wrap .text1 {
  font-size: 1.5em;
  color: #fff;
  margin-bottom: .5em;
}

.logo-wrap .text2 {
  font-size: 1em;
  color: #fff;
}

.login-container {
  background: #03305E;
  border-radius: 5px;
  padding: 1.5em;
  /* Change autocomplete styles in WebKit */
}

@media (min-width: 900px) {
  .login-container {
    padding: 2em;
    width: 24em;
  }
}

.login-container .title {
  color: #fff;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: .5em;
}

@media (min-width: 900px) {
  .login-container .title {
    font-size: 1.6em;
    margin-top: 2em;
    margin-bottom: 2.5em;
  }
}

.login-container button {
  margin: 0;
}

.login-container .ant-form-item {
  margin-bottom: 15px;
}

.login-container input:-webkit-autofill,
.login-container input:-webkit-autofill:hover,
.login-container input:-webkit-autofill:focus {
  border: 1px solid #3A86C0;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #3A86C0 inset;
  transition: background-color 5000s ease-in-out 0s;
}

#horizontal_login_username,
#horizontal_login_password {
  background-color: #3A86C0 !important;
  color: #fff;
  border: 1px solid #3A86C0 !important;
  display: block;
  font-size: 1.1em;
  margin-bottom: .5em;
  padding: .6em 1em .6em 2em;
  width: 100%;
  border-radius: 5px;
}

#horizontal_login_username:focus,
#horizontal_login_password:focus {
  background-color: #3A86C0 !important;
  outline: 0;
}

#horizontal_login_username::-webkit-input-placeholder,
#horizontal_login_password::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}

#horizontal_login_username:-moz-placeholder,
#horizontal_login_password:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}

#horizontal_login_username::-moz-placeholder,
#horizontal_login_password::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}

#horizontal_login_username:-ms-input-placeholder,
#horizontal_login_password:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

#horizontal_login_username::-ms-input-placeholder,
#horizontal_login_password::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

#horizontal_login_username::placeholder,
#horizontal_login_password::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}

/*
#horizontal_login_username {
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-left: 2.4em;
    background-image: url("../../assets/img/ico-user.svg");
}

#horizontal_login_password {
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-left: 2.4em;
    background-image: url("../../assets/img/ico-clave.svg");
}
*/
