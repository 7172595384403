.App {

}

@media (min-width: 900px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}